import React, { Suspense, useState, useEffect } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import Loader from './component/loader';
import './App.css';
import TagManager from 'react-gtm-module'
import IsolatedRoutes from './component/routes/';

const Header1 = React.lazy(() => import('./layouts/header/Header1'));
const FooterStyle01 = React.lazy(() => import('./layouts/footer/footer-style-01'));
const BackTop = React.lazy(() => import('./component/back-top'));

// Home
const HomeIndex = React.lazy(() => import('./pages/home-infinite/index'));

// Pages
const ContactUs = React.lazy(() => import('./pages/contact/contact'));
const Error404 = React.lazy(() => import('./pages/error404/error-page'));
const CrewForHire = React.lazy(() => import('./pages/crew-for-hire'));
const TeamSingle = React.lazy(() => import('./pages/team/team-single'))

// Equipment
const EquipmentCameras = React.lazy(() => import('./pages/equipment/equipment-cameras'));
const EquipmentCamerasSingle = React.lazy(() => import('./pages/equipment/equipment-cameras-details'));
const EquipmentLighting = React.lazy(() => import('./pages/equipment/equipment-lighting'));
const EquipmentLightingSingle = React.lazy(() => import('./pages/equipment/equipment-lighting-details'));
const EquipmentElectric = React.lazy(() => import('./pages/equipment/equipment-electric'));
const EquipmentElectricSingle = React.lazy(() => import('./pages/equipment/equipment-electric-details'));
const EquipmentSound = React.lazy(() => import('./pages/equipment/equipment-sound'));
const EquipmentSoundSingle = React.lazy(() => import('./pages/equipment/equipment-sound-details'));
const EquipmentGrip = React.lazy(() => import('./pages/equipment/equipment-grip'));
const EquipmentGripSingle = React.lazy(() => import('./pages/equipment/equipment-grip-details'));
const EquipmentExpendables = React.lazy(() => import('./pages/equipment/equipment-expendables'));
const EquipmentExpendablesSingle = React.lazy(() => import('./pages/equipment/equipment-expendables-details'));
const EquipmentList = React.lazy(() => import('./pages/equipment/equipment-list'));

//TCs
const TCs = React.lazy(() => import('./pages/tcs'));

// All Elements
// const ElementsActionBox = React.lazy(() => import('./elements/action-box'));
// const ElementsAccordion = React.lazy(() => import('./elements/accordion'));
// const ElementsButton = React.lazy(() => import('./elements/button'));
// const ElementsBlog = React.lazy(() => import('./elements/blog'));
// const ElementsClientLogo = React.lazy(() => import('./elements/client-logo'));
// const ElementsServices = React.lazy(() => import('./elements/services'));
// const ElementsServiceStep = React.lazy(() => import('./elements/service-step'));
// const ElementsSectionTitle = React.lazy(() =>import('./elements/section-title'));
// const ElementsCountdown = React.lazy(() => import('./elements/countdown'));
// const ElementsCounter = React.lazy(() => import('./elements/counter'));
// const ElementsTestimonial = React.lazy(() => import('./elements/testimonial'));
// const ElementsPortfolio = React.lazy(() => import('./elements/portfolio'));
// const ElementsTeam = React.lazy(() => import('./elements/team'));
// const ElementsList = React.lazy(() => import('./elements/list'));
// const ElementsVideo = React.lazy(() => import('./elements/video'));

const TRACKING_ID = "G-Q7QB46HN1L";
const tagManagerArgs = {
  gtmId: { TRACKING_ID }
}
TagManager.initialize(tagManagerArgs);

function App() {
  const [cartItems, setCartItems] = useState([]);
  const hideFooter = false;
  const location = useLocation();

  const onAddEquipment = (equipment) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "select_content",
      title: equipment.title
    })

    const existingItem = cartItems.find(x => x.title === equipment.title);
    if (existingItem) {
      if (equipment.availableqty > 0) {
        setCartItems(cartItems.map((x) => x.title === equipment.title ? { ...existingItem, qty: existingItem.qty + 1, availableqty: existingItem.availableqty - 1 } : x));
        equipment.availableqty -= 1;
      }
    }
    else if (equipment.availableqty > 0) {
      setCartItems([...cartItems, { ...equipment, qty: 1 }]);
      equipment.availableqty -= 1;
    }
  }

  return (
    <Suspense fallback={<Loader />}>
      <div className="main-wrapper">
        <IsolatedRoutes cartItems={cartItems} onAddEquipment={onAddEquipment} />
        {!hideFooter && (
          <Routes>
            <Route path="/*" element={<FooterStyle01 />} />{' '}
          </Routes>
        )}

        {/* Back to Top */}
        <BackTop />
      </div>
    </Suspense>
  );
}

export default App;
